import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "menuLabel": "For Designers",
  "sortOrder": 1,
  "title": "Getting Started for Designers"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Getting Started For Designers`}</h1>
    <p className="p-large">You can start using Caesars UI right away. All you need is a Figma account.</p>
    <p>{`Tokens and components are available for you to use in your designs.`}</p>
    <h2>{`How to use the Caesars UI Kit in Figma`}</h2>
    <ol>
      <li parentName="ol">{`Sign into Figma using your Caesars account.`}</li>
      <li parentName="ol">{`Open a design file.`}</li>
      <li parentName="ol">{`Select the Figma logo to toggle the menu and then select "Libraries" in the menu.`}</li>
      <li parentName="ol">{`In the Libraries modal, search for "CUI - UI Kit" libaries.`}</li>
      <li parentName="ol">{`Toggle on "CUI - UI Kit" if it isn't toggled already.`}</li>
      <li parentName="ol">{`You should see the Caesars UI components in your assets library.`}</li>
    </ol>
    <h2>{`How to use the Caesars UI color palette in Figma`}</h2>
    <ol>
      <li parentName="ol">{`The color palette will be available when you turn on the CUI UI Kit library.`}</li>
    </ol>
    <p>{`We use semantic color tokens in our Figma files. Learn more about `}<a parentName="p" {...{
        "href": "/tokens/colors"
      }}>{`color tokens`}</a>{` to see why we've approached it this way.`}</p>
    <h2>{`Themes`}</h2>
    <p>{`We use variables to access different brand themes. Use Figma's theme switcher to automatically switch your frames to other themes.`}</p>
    <h2>{`Need Help?`}</h2>
    <p>{`Reach out to us on `}<a parentName="p" {...{
        "href": "https://join.slack.com/share/enQtMzgyODMxNDQyMTc5Ni05ZjQ3OTc3YzA1NDhmOTZhOTZjOTkxMjEzMTIwYzc2MTY5ZDcyMGNkZmU1MDYwMTdjODBkNTI5ODg3OTVkZmNm"
      }}>{`Slack`}</a>{` and we'll get you on track.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      